<template>
  <div id="article-detail-page">
    <div
      class="banner cvr-bg"
      style="background-image: url('/img/dummy/about/article/detail/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ $t("aboutUs.Article") }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="detail-article">
      <div class="wrapper">
        <div class="crumb">
          <router-link to="/about-us/article">
            {{ $t("aboutUs.Article") }}
          </router-link>
          <i class="cvr-bg"></i>
          <span>{{ title }}</span>
        </div>
        <!-- end of crumb -->

        <div class="rowflex">
          <article class="main">
            <figure v-if="cover">
              <img :src="cover" />
            </figure>
            <h1>{{ title }}</h1>
            <div class="util">
              <span>
                {{ date | date_only }}
              </span>
              <div class="share">
                <b class="cvr-bg-bf">{{ $t("aboutUs.Share") }}</b>
              </div>
              <!-- end of share -->
              <!-- ShareThis BEGIN -->
              <ShareNetwork
                v-for="network in networks"
                :key="network.name"
                :network="network.name"
                :style="network.linkStyle"
                :url="articleLink"
                :title="title"
                :description="description"
              >
                <i :class="network.icon" :style="network.iconStyle"></i>
              </ShareNetwork>
              <!-- ShareThis END -->
              <a href="#" @click="copyLink">
                <img src="/img/icon/link.png" style="width: 29px" />
              </a>
            </div>
            <!-- end of util -->

            <div class="format-text" v-html="description"></div>
            <!-- end of format text -->
          </article>
          <!-- end of main -->
          <aside class="sidebar">
            <div class="widget">
              <h2>{{ $t("aboutUs.LatestArticle") }}</h2>
              <div class="wrap">
                <template v-for="(art, index) in latest">
                  <div class="item" :key="index" v-if="index < 2">
                    <figure>
                      <router-link :to="`/about-us/article/${art.id}`">
                        <img :src="art.cover" />
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/about-us/article/${art.id}`">
                          {{ art.title }}
                        </router-link>
                      </h3>
                      <span>{{ art.date | date_only }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="widget">
              <h2>{{ $t("aboutUs.FeaturedArticle") }}</h2>
              <div class="wrap">
                <template v-for="(art, index) in featured">
                  <div class="item" :key="index" v-if="index < 3">
                    <figure>
                      <router-link :to="`/about-us/article/${art.id}`">
                        <img :src="art.cover" />
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/about-us/article/${art.id}`">
                          {{ art.title }}
                        </router-link>
                      </h3>
                      <span>{{ art.date | date_only }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- end of widget -->
          </aside>
          <!-- endof sidebar -->
        </div>
        <!-- end of rowflex -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of detail article  -->
  </div>
</template>

<script>
export default {
  name: "ArticleDetail",
  metaInfo() {
    return {
      title: this.title
    };
  },
  data() {
    return {
      title: "",
      cover: null,
      date: null,
      description: "",
      latest: [],
      featured: [],
      networks: [
        {
          name: "facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          iconStyle: "padding: 0.4em; color: white",
          linkStyle: "background-color: #1877f2; margin: 2px"
        },
        {
          name: "twitter",
          icon: "fab fah fa-lg fa-twitter",
          iconStyle: "padding: 0.4em; color: white",
          linkStyle: "background-color: #1da1f2; margin: 2px"
        },
        {
          name: "whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          iconStyle: "padding: 0.4em; color: white",
          linkStyle: "background-color: #25d366; margin: 2px"
        },
        {
          name: "line",
          icon: "fab fah fa-lg fa-line",
          iconStyle: "padding: 0.4em; color: white",
          linkStyle: "background-color: #00c300; margin: 2px"
        },
        {
          name: "linkedin",
          icon: "fab fah fa-lg fa-linkedin",
          iconStyle: "padding: 0.4em; color: white",
          linkStyle: "background-color: #007bb5; margin: 2px"
        }
      ]
    };
  },
  computed: {
    articleLink() {
      return `https://rubii.id/about-us/articles`;
      // return document.URL;
    }
  },
  mounted() {
    this.getDetailArticle();
    this.getLatestArticle();
    this.getFeaturedArticle();
  },
  methods: {
    getDetailArticle() {
      this.$axios.get(`/articles/${this.$route.params.id}`).then(response => {
        this.title = response.data.data.title;
        this.cover = response.data.data.cover;
        this.date = response.data.data.date;
        this.description = response.data.data.longDescription;
      });
    },
    getLatestArticle() {
      this.$axios
        .get(`/articles`, {
          params: {
            lang: null,
            page: 1,
            limit: 3,
            sortBy: "date.desc"
          }
        })
        .then(response => {
          this.latest = response.data.data.rows;
        });
    },
    getFeaturedArticle() {
      this.$axios
        .get(`/articles`, {
          params: {
            lang: null,
            page: 1,
            limit: 3,
            favorite: true
          }
        })
        .then(response => {
          this.featured = response.data.data.rows;
        });
    },
    copyLink() {
      navigator.clipboard.writeText(
        `${location.hostname}${this.$route.fullPath}`
      );
      this.$swal({
        icon: "success",
        text: "Article Link Copied to Clipboard"
      });
    }
  }
};
</script>
